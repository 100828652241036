<template>
  <div class="home">
    <el-row class="home-top" :gutter="20">
      <el-col :span="10">
        <user-card></user-card>
        <!-- 接入设备总量 -->
        <el-row class="" :gutter="20">
          <!-- <el-col :span="7">
            <gas-amount class="space-21"></gas-amount>
          </el-col>-->
          <el-col :span="24">
            <device-statis
              class="space-21"
              @open-meter-rate-dialog="handleOpen"
            ></device-statis>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="7">
        <!-- 抄表率 -->
        <meter-rate ref="meterRate"></meter-rate>
        <!-- 近7日每日通讯上报数对比 -->
        <last-compare class="space-21"></last-compare>
      </el-col>
      <el-col :span="7">
        <!-- 重点告警汇总 -->
        <high-alarm></high-alarm>
      </el-col>
    </el-row>
    <el-row class="home-bottom space-21" :gutter="20">
      <el-col :span="10">
        <!-- 昨日用户用气量Top5 -->
        <use-gas-top5></use-gas-top5>
      </el-col>
      <el-col :span="7">
        <!-- 月用气总量趋势 -->
        <use-gas-trend></use-gas-trend>
      </el-col>
      <el-col :span="7">
        <!-- 月设备发展量趋势 -->
        <device-develop-trend></device-develop-trend>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserCard from "./components/User.vue";
// import GasAmount from "./components/GasAmount.vue";
import DeviceStatis from "./components/DeviceStatis.vue";
import MeterRate from "./components/MeterRate.vue";
import LastCompare from "./components/LastCompare.vue";
import HighAlarm from "./components/HighAlarm.vue";
import UseGasTop5 from "./components/UseGasTop5.vue";
import UseGasTrend from "./components/UseGasTrend.vue";
import DeviceDevelopTrend from "./components/DeviceDevelopTrend.vue";
import { mapGetters, mapMutations } from "vuex";
import { isProd } from "@/config";

export default {
  name: "Home",
  components: {
    UserCard,
    // GasAmount,
    DeviceStatis,
    MeterRate,
    LastCompare,
    HighAlarm,
    UseGasTop5,
    UseGasTrend,
    DeviceDevelopTrend,
  },
  data() {
    return {
      websocket: null,
      timer: null,
      alarmCount: 0,
      isWsClose: false,
      cacheLastAlarmCount: 0, // 记录websocket关闭前的告警数，便于重连后判断
      reconnectTimes: 0, // websocket断开重连次数
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  mounted() {
    if (this.$has("monitor:run:alarm-record:select")) {
      this.initWebSocket();
    }
  },
  methods: {
    ...mapMutations({
      setAlarmCount: "user/SET_ALARM_COUNT",
    }),
    handleOpen() {
      this.$refs.meterRate.handleOpenDialog();
    },
    initWebSocket() {
      let { tenantId, orgnization, id } = this.userInfo;
      const {
        hostname: _hostname,
        protocol: _protocol,
        port: _port,
      } = window.location;
      if (!isProd && !tenantId) tenantId = 1104;
      const host = isProd
        ? `${_hostname}:${_port ? _port : "32080"}`
        : "iot.hg.eslink.net.cn:32080";
      const protocol = _protocol === "http:" ? "ws" : "wss";
      this.websocket = new WebSocket(
        `${protocol}://${host}/monitor/alarm-push/${tenantId}/${
          orgnization?.code || "default"
        }/${id}`
      );
      // 监听连接成功
      this.websocket.onopen = () => {
        console.log("连接建立成功");
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
        // 重连次数超过10次时不再进行重连
        if (this.reconnectTimes > 10) {
          console.warn("重连次数过于频繁，请刷新页面重试或联系管理员");
          return;
        }
        this.reconnectTimes += 1;
        this.timer = setInterval(() => {
          this.websocket.send("ping");
        }, 30 * 1000);
      };
      // 监听服务端消息(接收消息)
      this.websocket.onmessage = (e) => {
        console.log("接收服务端返回数据", e);
        this.checkHeartBeat();
        if (e.data.indexOf("alarmCount") > -1) {
          const { alarmCount } = JSON.parse(e.data);
          this.setAlarmCount(alarmCount);
          if (this.isWsClose) {
            this.isWsClose = false;
            if (this.cacheLastAlarmCount === alarmCount) return;
          }
          if (alarmCount && this.$route.path !== "/big-screen") {
            this.$notify({
              title: "新消息提醒",
              message: `您有${alarmCount}条新的告警消息！`,
              iconClass: "none",
            });
          }
        }
      };
      // 监听连接失败重连
      this.websocket.onerror = (error) => {
        console.log("连接建立失败", error);
      };
      // 监听连接关闭
      this.websocket.onclose = (e) => {
        this.isWsClose = true;
        this.cacheLastAlarmCount = this.alarmCount;
        console.log("websocket断开连接", e);
      };
    },
    checkHeartBeat() {
      setTimeout(() => {
        if (this.websocket?.readyState !== 1) {
          this.initWebSocket();
        } else {
          const time = Date.parse(new Date()); // 当前时间戳
          const data = JSON.stringify({ time });
          this.websocket.send(data);
        }
      }, 30 * 1000);
    },
  },
  beforeDestroy() {
    this.websocket.close();
    clearInterval(this.timer);
  },
};
</script>
<style scoped lang="scss">
::v-deep .card {
  padding: 24px;
  background: #f2f4fc;
  border-radius: 16px;
  position: relative;
  &-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    letter-spacing: 1px;
  }
  .box-number {
    position: absolute;
    left: 24px;
    bottom: 24px;
    .text {
      font-size: 12px;
      color: #999;
    }
    .data {
      margin-top: 10px;
      .unit {
        font-size: 16px;
        font-weight: normal;
        color: #333;
        line-height: 24px;
      }
    }
  }
  .bold {
    font-size: 20px;
    font-family: Impact;
    color: #4d4d4d;
    line-height: 24px;
    letter-spacing: 1px;
  }
}
.home {
  height: 100%;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &-top {
    flex: 0 0 515px;
  }

  &-bottom {
    flex: 1;
    min-height: 300px;
    .el-col {
      height: 100%;
      overflow: hidden;
    }
  }
}
.space-21 {
  margin-top: 21px;
}

@media screen and (max-width: 1860px) {
  ::v-deep .card {
    padding: 16px;
    &-title {
      font-size: 17px;
      font-weight: 600;
      color: #333;
      letter-spacing: 1px;
    }
  }
}
</style>
