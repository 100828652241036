<template>
  <div class="card">
    <h2 class="card-title">昨日用户用气量Top5</h2>

    <ul class="items" v-if="list.length">
      <li class="item" v-for="(item, index) in list" :key="index">
        <i class="dot"></i>
        <div class="content">
          <div class="left">
            <span>设备编号：{{ item.deviceNo }}</span>
            <span class="split">|</span>
            <span>设备类型： {{ item.deviceTypeName }}</span>
          </div>
          <div class="right">
            <span class="bold">{{ item.usedVol }}</span>
            m³
          </div>
        </div>
      </li>
    </ul>
    <gc-empty v-else></gc-empty>
  </div>
</template>

<script>
import { apiUseGasTop5 } from "@/api/home";

export default {
  name: "UseGasTop5",
  data() {
    return {
      type: 0,
      list: [],
    };
  },
  methods: {
    handleJump(item) {
      if (!this.$has("monitor:statistical:amount")) {
        this.$message.error("您没有操作权限！");
        return;
      }
      const yesterday = +new Date().getTime() - 24 * 60 * 60 * 1000;
      const date = this.dayjs(yesterday).format("YYYY-MM-DD");
      const startDate = `${date} 00:00:00`;
      const endDate = `${date} 23:59:59`;
      this.$router.push({
        name: "useGas",
        params: {
          deviceNo: item.deviceNo,
          userNo: item.userNo,
          timeType: "1",
          dateSection: [startDate, endDate],
        },
      });
    },
    getUseGasTop5() {
      apiUseGasTop5()
        .then(({ list = [] }) => {
          this.list = list;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  activated() {
    this.getUseGasTop5();
  },
};
</script>
<style scoped lang="scss">
.card {
  height: 100%;
  overflow: auto;
  position: relative;
}
.items {
  padding-top: 16px;
  .item {
    margin-top: 16px;
    display: flex;
    &:last-of-type .content {
      border-bottom: none;
      padding-bottom: 0;
    }
    .dot {
      flex: 0 0 12px;
      height: 12px;
      background: #a1aad8;
      border-radius: 6px;
      margin-top: 6px;
    }
    .content {
      flex: 1;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 18px;
      border-bottom: 1px dashed #c3c9e6;
      .left {
        flex: 1;
        .split {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
      .right {
        flex: 0 0 180px;
        text-align: right;
        .iconfont {
          margin-left: 20px;
        }
      }
    }
  }
}
::v-deep {
  .gc-blank-page {
    margin-top: 20px;
    .no-data-img {
      width: 133px;
      height: 120px;
    }
  }
}
</style>
