<template>
  <div id="barChart" class="chart-container" />
</template>
<script>
const echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");
require("echarts/lib/component/grid");
require("echarts/lib/component/tooltip");

export default {
  name: "BarChart",
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              opacity: 0,
            },
          },
        },
        grid: {
          left: "0%",
          right: "0%",
          bottom: "20px",
          top: "0%",
        },
        xAxis: {
          type: "category",
          data: [],
          axisLine: {
            show: false,
            lineStyle: {
              color: "#666",
            },
          },
          axisTick: { show: false },
          axisLabel: {
            interval: 0,
          },
        },

        yAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "18px",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#A1AFF4",
                  },
                  {
                    offset: 1,
                    color: "#ADC2ED",
                  },
                ]),
                barBorderRadius: 11,
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  watch: {
    chartData: {
      handler() {
        this.updateChart();
      },
      deep: true,
    },
  },
  methods: {
    updateChart() {
      const chartDom = document.getElementById("barChart");
      const chart = echarts.init(chartDom);
      this.options.xAxis.data = this.chartData.map((item) => item.date);
      this.options.series[0].data = this.chartData.map((item) => item.count);
      chart.clear();
      chart.setOption(this.options);
      addEventListener("resize", () => {
        chart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
