<template>
  <div class="card">
    <h2 class="card-title">月用气总量趋势</h2>
    <div class="box-chart">
      <trend-chart
        v-show="chartData.length"
        :chart-data="chartData"
      ></trend-chart>
      <gc-empty v-show="!chartData.length"></gc-empty>
    </div>
    <div class="box-total">
      <div class="item">
        <p class="item-label">月均用气量(m³)</p>
        <p class="item-data bold">{{ average || 0 }}</p>
      </div>
      <div class="item">
        <p class="item-label">最大用气量(m³)</p>
        <p class="item-data bold">{{ max || 0 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import TrendChart from "./TrendChart.vue";
import { apiGasTrend } from "@/api/home";

export default {
  name: "UseGasTrend",
  components: {
    TrendChart,
  },
  data() {
    return {
      average: 0,
      max: 0,
      chartData: [],
    };
  },
  methods: {
    handleJump() {
      if (!this.$has("monitor:statistical:amount")) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.$router.push({
        name: "useGas",
        params: {
          queryCurrentMonth: true,
          timeType: "0",
        },
      });
    },
    getGasTrend() {
      apiGasTrend()
        .then(({ average, max, list = [] }) => {
          this.average = average;
          this.max = max;
          this.chartData = list.map((item) => {
            return {
              date: item.yearMonth,
              count: item.amount,
            };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  activated() {
    this.getGasTrend();
  },
};
</script>
<style scoped lang="scss">
.card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.box-chart {
  margin-top: 20px;
  width: 100%;
  // height: 180px;
  flex: 1;
}
.box-total {
  margin-top: 10px;
  display: flex;
  .item {
    flex: 1;
    text-align: center;
    &-label {
      color: #9ca0b3;
    }
    &-data {
      margin-top: 12px;
    }
  }
}
::v-deep {
  .gc-blank-page {
    margin-top: 0;
    padding-top: 20px;
    .no-data-img {
      width: 133px;
      height: 120px;
    }
  }
}
</style>
