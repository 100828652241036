<template>
  <div class="card">
    <h2 class="card-title">物联网设备重点告警汇总</h2>

    <ul class="items" v-if="highAlarms.length">
      <li
        class="item"
        v-for="(item, index) in highAlarms"
        :key="index"
        @click="linkAlarmRecords(item.classification)"
      >
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="`#${item.icon}`"></use>
        </svg>
        <span class="title">{{ item.alarmName }}</span>
        <span class="bold">{{ item.count || 0 }}</span>
      </li>
    </ul>
    <gc-empty v-else></gc-empty>
  </div>
</template>

<script>
import { apiHighAlarmCountBoard } from "@/api/home";

const IconMap = {
  1: "icon-shebeiguzhanggaojing",
  2: "icon-liulianggaojing1",
  3: "icon-yaligaojing1",
  4: "icon-wendugaojing1",
  5: "icon-xielougaojing",
  6: "icon-didianyagaojing1",
  7: "icon-famenguanbigaojing",
  8: "icon-shebeiyichanggaojing",
};

export default {
  name: "HighAlarm",
  data() {
    return {
      highAlarms: [],
    };
  },
  methods: {
    linkAlarmRecords(alarmType) {
      if (!this.$has("monitor:run:alarm-record:select")) {
        this.$message.error("您没有操作权限！");
        return;
      }
      this.$router.push({
        name: "alarmRecords",
        params: {
          alarmType: String(alarmType),
          firstCategory: this.firstCategory,
        },
      });
    },
    getHighAlarmData() {
      apiHighAlarmCountBoard()
        .then(({ records = [] }) => {
          this.highAlarms = records.map((item, index) => {
            return {
              ...item,
              icon: IconMap[item.classification] || "",
            };
          });
          console.log("highAlarms: ", this.highAlarms);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  activated() {
    this.getHighAlarmData();
  },
};
</script>
<style scoped lang="scss">
.card {
  height: 515px;
}
.items {
  padding-top: 20px;
  .item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      flex: 0 0 40px;
      height: 40px;
      border-radius: 5px;
    }
    .title {
      flex: 1;
      margin-left: 26px;
      font-size: 16px;
      font-weight: 400;
      color: #333;
      white-space: nowrap;
    }
    .bold {
      flex: 0 0 46px;
      text-align: right;
    }
  }
}
</style>
